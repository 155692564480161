import PAGE_URLS from 'constants/pageUrls';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useSyncLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === '__iAGSHFAW') {
        navigate(`/${PAGE_URLS.HOME}`);
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
};

export default useSyncLogout;
