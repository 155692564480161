const useUserOperations = () => {
  const createUser = () => {
    // __iAGSHFAW => __iAmGonnaStayHereForAWhile
    localStorage.setItem('__iAGSHFAW', JSON.stringify('true'));
  };
  const isLoggedIn = !!localStorage.getItem('__iAGSHFAW');
  const removeUser = () => {
    localStorage.removeItem('__iAGSHFAW');
  };
  return { createUser, removeUser, isLoggedIn };
};

export default useUserOperations;
